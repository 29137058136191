import React from "react"
import Link from "gatsby-link"
//import Bulma from '@vizuaalog/bulmajs';
//import Navbar from '@vizuaalog/bulmajs/src/plugins/Navbar';

class IRNav extends React.Component {
  state = {
    isActive: false,
  }

  toggleNav = () => {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }))
  }

  render() {
    return (
      <nav
        className={this.state.isActive ? "navbar is-active" : "navbar"}
        role="navigation"
        aria-label="main navigation"
        style={{ zIndex: "1000" }}
      >
        <button
          id="nav-burger"
          className="button navbar-burger"
          onClick={this.toggleNav}
        >
          <span />
          <span />
          <span />
        </button>

        <div
          id="navbarBasicExample"
          className={
            this.state.isActive ? "navbar-menu is-active" : "navbar-menu"
          }
        >
          <div class="navbar-start">
            <div class="navbar-item">
              <a class="navbar-link" href="/investorcentre">
                IR Home
              </a>
            </div>

            <div class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link">Corporate Information</a>
              <div class="navbar-dropdown">
                <a
                  class="navbar-item"
                  href="/investorcentre/corporateinfo/general"
                >
                  General Corporate Information
                </a>
                <a
                  class="navbar-item"
                  href="/investorcentre/corporateinfo/keymanagement"
                >
                  Key Management
                </a>
                <a
                  class="navbar-item"
                  href="/investorcentre/corporateinfo/corporatestructure"
                >
                  Corporate Structure
                </a>
                <a
                  class="navbar-item"
                  href="/investorcentre/corporateinfo/properties"
                >
                  Our Properties
                </a>
                <a
                  class="navbar-item"
                  href="/investorcentre/corporateinfo/subsidaries"
                >
                  Our Subsidaries
                </a>
              </div>
            </div>

            <div class="navbar-item">
              <a class="navbar-link" href="/investorcentre/corporategovernance">
                Corporate Governance
              </a>
            </div>

            <div class="navbar-item">
              <a
                class="navbar-link"
                href="/investorcentre/corporatepresentations"
              >
                Corporate Presentation
              </a>
            </div>

            <div class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link">Management Discussion & Analysis</a>
              <div class="navbar-dropdown">
                <a class="navbar-item" href="/investorcentre/chairmanstatement">
                  Chairman's Statement
                </a>
                <a class="navbar-item" href="/investorcentre/mdna">
                  MD&A
                </a>
              </div>
            </div>

            <div class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link">Reports</a>
              <div class="navbar-dropdown">
                <a class="navbar-item" href="/investorcentre/annualreport">
                  Annual Reports
                </a>
                <a class="navbar-item" href="/investorcentre/quarter">
                  Quarterly Reports
                </a>
                <a class="navbar-item" href="/investorcentre/prospectus">
                  Prospectus
                </a>
                <a class="navbar-item" href="/investorcentre/circular">
                  Circulars
                </a>
                <a class="navbar-item" href="/investorcentre/analyst">
                  Analyst Reports
                </a>
                <a class="navbar-item" href="/investorcentre/bursaanouncements">
                  Bursa Anouncements
                </a>
              </div>
            </div>

            <div class="navbar-item">
              <a class="navbar-link" href="/investorcentre/calendar">
                Corporate Calendar
              </a>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default IRNav
