import React from "react"
import Link from "gatsby-link"
import NoSSR from "react-no-ssr"
import BackgroundSlider from "react-background-slider"
import IRImg from "./ir.jpg"

class IRBG extends React.Component {
  render() {
    return (
      <NoSSR>
        <BackgroundSlider images={[IRImg]} duration={10000} transition={0} />
      </NoSSR>
    )
  }
}

export default IRBG
