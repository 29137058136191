import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import NavBar from "../components/NavBar"
import IRNav from "../components/IRNav"
import Footer from "../components/Footer"
import "../css/styles.sass"
import config from "../../meta/config"

const IRPageWrapper = ({ children }) => (
  <div>
    <Helmet>
      <title>{config.siteTitle}</title>
      <meta name="description" content={config.siteDescription} />
    </Helmet>
    <NavBar />
    <div>{children}</div>
    <Footer />
  </div>
)

IRPageWrapper.propTypes = {
  children: PropTypes.any,
}

export default IRPageWrapper
