import React from "react"
import Link from "gatsby-link"
import HomeLogoImg from "./ealogonew.png"

class HomeLogo extends React.Component {
  render() {
    return (
      <div style={{ maxWidth: `100px` }}>
        <a href="/home">
          <img src={HomeLogoImg} />
        </a>
      </div>
    )
  }
}

export default HomeLogo
