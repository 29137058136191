/**
 * Created by vaibhav on 31/3/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import BackgroundSlider from "react-background-slider"
import IRPageWrapper from "../../../layouts/IRPageWrapper"
import IRNav from "../../../components/IRNav"
import BGImg from "../ir.jpg"
import HomeLogo from "../../../components/HomeLogo"
import IRBG from "../../../components/IRBG"
import DownloadImg from "./download.png"
import PresentationPDF from "./presentation.pdf"

// import InvestorCentrePost from '../../components/InvestorCentrePost'

export default class CorporatePresentationPage extends Component {
  render() {
    const { edges: presentations } = this.props.data.allMarkdownRemark

    return (
      <IRPageWrapper>
        <IRBG />

        <Helmet>
          <title>InvestorCentre - EA Technique</title>
        </Helmet>
        <section
          className="hero is-fullheight"
          style={{ display: `inline-block` }}
        >
          <section className="hero is-small">
            <div className="columns">
              <div className="column is-offset-1">
                <div className="section">
                  <HomeLogo />

                  <h1 className="title" id="title-line">
                    Corporate Presentations
                  </h1>
                  <IRNav />
                </div>
              </div>
            </div>
          </section>
          <section className="section">
            <div className="column is-offset-1">
              <table id="customers" style={{ width: `100%` }}>
                <tr>
                  <td>Title</td>
                  <td>Date</td>
                  <td>Download</td>
                </tr>
                {presentations.map(({ node: presentation }, index) => (
                  <tr key={index}>
                    <td>{presentation.frontmatter.title}</td>
                    <td>{presentation.frontmatter.date}</td>
                    <td>
                      <a href={presentation.frontmatter.manual_pdf} download>
                        <img src={DownloadImg} />
                      </a>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </section>
        </section>
      </IRPageWrapper>
    )
  }
}

export const presentationPageQuery = graphql`
  query PresentationPage {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "presentation-page" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            manual_pdf
          }
        }
      }
    }
  }
`
